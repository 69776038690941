<template>
  <div class="accessList common-page groupList">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:用户组管理') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:用户组列表') }}</span>
      </template>
      <template slot="headerGroup">
        <div class="groupBox">
          <el-input
            style="width: 240px; margin-right: 10px"
            v-model="pageConfig.groupName"
            :placeholder="$t('dc:请输入用户组名称')"
            show-word-limit
            @keyup.enter.native="queryApi"
            @clear="queryApi"
          >
            <el-button
              class="btn-black"
              slot="append"
              @click="queryApi"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <el-button class="btn-black" type="info" @click="onAdd">{{
            $t('dc:新增用户组')
          }}</el-button>
        </div>
      </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        :row-class-name="tableRowClassName"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.chlidren ? null : item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          :align="item.align"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.key] }}</span>
          </template>
          <el-table-column
            v-for="chlid in item.chlidren"
            v-if="chlid.visible"
            :key="chlid.key"
            :prop="chlid.key"
            :label="chlid.name"
            :fixed="chlid.fixed"
            :sorter="chlid.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          ></el-table-column>
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="220" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <el-tooltip effect="dark" :content="$t('dc:编辑')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-edit padding12 nicon"
                  @click="showEdit(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('dc:选择成员')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-mdm-client padding12 nicon"
                  @click="showSelectMember(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('dc:分配')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-distribution padding12 nicon"
                  @click="showDistribute(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip
                v-if="scope.row.status === 1"
                effect="dark"
                :content="$t('dc:禁用')"
                placement="top-start"
              >
                <i
                  :class="'icon-oneui-contract-disable'"
                  class="disable poc-size12 iconfont padding12 nicon"
                ></i>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                :content="
                  scope.row.status === 0
                    ? $t('dc:禁用')
                    : scope.row.status === 3
                    ? $t('dc:恢复')
                    : $t('dc:驳回')
                "
                placement="top-start"
              >
                <i
                  v-if="scope.row.status === 0"
                  :class="'icon-oneui-contract-disable'"
                  class="poc-icon-info poc-size12 iconfont padding12 nicon"
                  @click="forbid(scope.row)"
                ></i>
                <!-- <i v-if="scope.row.status === 1 " :class="'icon-oneui-contract-dismissed'" class="poc-icon-info poc-size12 iconfont padding12" @click="reject(scope.row)"></i> -->
                <i
                  v-if="scope.row.status === 3"
                  :class="'icon-oneui-reload'"
                  class="poc-icon-info poc-size12 iconfont padding12 nicon"
                  @click="recover(scope.row)"
                ></i>
              </el-tooltip>

              <el-tooltip effect="dark" :content="$t('dc:删除')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-delete padding12 nicon"
                  @click="delectAccess(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('dc:转变')" placement="top-start">
                <i
                  v-if="scope.row.visible"
                  class="poc-icon-info poc-size12 iconfont icon-oneui-eyes-open padding12 nicon"
                  @click="convertAccess(scope.row)"
                ></i>
                <i
                  v-else
                  class="poc-icon-info poc-size12 iconfont icon-oneui-eyes-close padding12 nicon"
                  @click="convertAccess(scope.row)"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <!-- <groupDailog v-if="showDialog" :visible.sync="showDialog" :title="title" :content="content"></groupDailog> -->
    <addEditGroupDialog
      v-if="showDialog"
      :visible.sync="showDialog"
      :rawData="rawData"
      :title="title"
      :content="content"
      @queryApi="queryApi"
    ></addEditGroupDialog>
    <accessAllocationDialog
      v-if="showAllocation"
      :visible.sync="showAllocation"
      :rawData="rawData"
    ></accessAllocationDialog>
    <groupUsuallyDialog
      v-if="shwoUsuallyDialog"
      :visible.sync="shwoUsuallyDialog"
      :title="groupTitle"
      :content="groupContent"
      @confrom="confrom"
    ></groupUsuallyDialog>
    <selectMemberDialog
      v-if="showSelectMemberDialog"
      :visible.sync="showSelectMemberDialog"
      :rawData="rawData"
      @queryApi="queryApi"
    ></selectMemberDialog>
  </div>
</template>

<script>
import groupDailog from '@/views/dialog/groupDailog'
import addEditGroupDialog from '@/views/dialog/addEditGroupDialog'
import selectMemberDialog from '@/views/dialog/selectMemberDialog'
import accessAllocationDialog from '@/views/dialog/accessAllocationDialog'
import groupUsuallyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'groupList',
  mixins: [$PCommon.TableMixin],
  components: {
    addEditGroupDialog,
    accessAllocationDialog,
    groupUsuallyDialog,
    selectMemberDialog,
  },
  data() {
    return {
      accessName: '',
      title: this.$t('dc:新增用户组'),
      content: '',
      groupTitle: '',
      groupContent: '',
      pageConfig: {
        groupName: '',
      },
      rawData: {},
      productList: [],
      loading: false,
      showDialog: false,
      showAccDialog: false,
      showAllocation: false,
      shwoUsuallyDialog: false,
      showSelectMemberDialog: false,
      accessobj: {},
      tableData: [],
      delRowData: {},
      groupStatusType: 0,
      userGroupId: -1,
    }
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'userGroupId',
          name: 'No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          align: 'left',
          fixed: false, // 固定列的位置(left, right)
          width: 50, // 默认宽度，像素
        },
        {
          key: 'userGroupName',
          name: this.$t('dc:用户组名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          align: 'left',
          fixed: false,
          width: null,
        },
        {
          key: 'userGroupAdmin',
          name: this.$t('dc:管理员'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: null,
        },
        {
          key: 'desc',
          name: this.$t('dc:描述'),
          dataType: 'string',
          visible: true,
          sortable: false,
          align: 'left',
          fixed: false,
          width: null,
        },
        {
          key: 'createTime',
          name: this.$t('dc:创建时间'),
          dataType: 'string',
          visible: true,
          sortable: false,
          align: 'left',
          fixed: false,
          width: null,
        },
        {
          key: 'numbersOfMembers',
          name: this.$t('dc:用户组人数'),
          dataType: 'number',
          visible: true,
          sortable: false,
          align: 'center',
          fixed: false,
          width: 96,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  created() {
    this.getProductList()
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.queryApi()
    },
    // 获取group数据
    async queryApi() {
      this.loading = true
      const { status, data } = await $Api.group.getGroupList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.pageConfig.total = data.total
        this.tableData = data.list
      }
      this.loading = false
    },
    // 禁用
    forbid(item) {
      this.groupStatusType = 3
      this.userGroupId = item.userGroupId
      this.groupTitle = this.$t('dc:禁用')
      this.groupContent = this.$t('dc:您确认要禁止该组吗?')
      this.shwoUsuallyDialog = true
    },
    // 恢复
    recover(item) {
      this.groupStatusType = 0
      this.userGroupId = item.userGroupId
      this.groupTitle = this.$t('dc:恢复')
      this.groupContent = this.$t('dc:您确认要恢复该组吗?')
      this.shwoUsuallyDialog = true
    },
    delectAccess(item) {
      this.groupStatusType = 4
      this.userGroupId = item.userGroupId
      this.groupTitle = this.$t('dc:删除')
      this.groupContent = this.$t('dc:您确认删除此访问权限吗?')
      this.shwoUsuallyDialog = true
    },
    convertAccess(item) {
      let params = {
        userGroupId: item.userGroupId,
        visible: !item.visible,
      }
      this.accessSwitch(params)
    },
    async accessSwitch(params) {
      let { status } = await $Api.group.upDataVisible(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.queryApi()
    },
    async confrom() {
      let params = {
        userGroupId: this.userGroupId,
        status: this.groupStatusType,
      }
      let { status, msg } = await $Api.group.updataStatus(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
        this.shwoUsuallyDialog = false
        this.queryApi()
      }
    },

    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },
    onAdd() {
      this.rawData = {}
      this.showDialog = true
    },
    showSelectMember(item) {
      this.rawData = item
      this.showSelectMemberDialog = true
    },
    showEdit(item) {
      this.rawData = item
      this.showDialog = true
    },
    showDistribute(item) {
      this.rawData = item
      this.showAllocation = true
    },
    tableRowClassName({ row, rowIndex }) {
      //状态为1时
      if (row.status === 3) return 'rowdisable'
    },
  },
}
</script>

<style lang="scss">
.groupBox {
  display: flex;
  justify-content: space-between;
}
.groupList.accessList {
  .access-allocation-dialog .el-table .cell.el-tooltip {
    // white-space: normal;
    span {
      display: inline-block;
    }
  }
  .disable {
    cursor: not-allowed;
    color: #ccc;
  }
  .rowdisable {
    color: #ccc;
    .action-btn-group {
      color: #606266;
    }
    .disable {
      cursor: not-allowed;
      color: #ccc;
    }
  }
}
</style>
